import React from "react";
import WorkSansWoff2 from "../fonts/work-sans-v7-latin/work-sans-v7-latin-regular.woff2";
import { Helmet } from "react-helmet";

export default function BaselineSeo() {
  return (
    <Helmet>
      <link
        rel="preload"
        as="font"
        href={WorkSansWoff2}
        type="font/woff2"
        crossorigin
      />
      <link rel="preconnect" href="https://cdn.electroniks.pk" crossorigin />
      <link
        rel="preconnect"
        href="https://storage.googleapis.com"
        crossorigin
      />

      <html xmlns="http://www.w3.org/1999/xhtml" xmlLang="en-US" lang="en-US" />
      <title>
        Electroniks.pk | Best mobile phone & electronics prices in Pakistan
      </title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Phone finder pakistan, Daily Updated Mobile Prices, Vivo Mobile Prices, Q Mobile prices, Samsung mobile prices, Iphone Mobile Prices Pakistan, Xioami Mobile Prices, Huawei Mobile Prices, Sony Ericsson Mobile"
      ></meta>
      <meta
        name="keywords"
        content="Itel mobile prices, Realme prices, Apple iphone Pakistan, Jazz digital, Tecno Mobile Prices, Infinix Mobile Prices, Samsung Mobile Prices , mobile phone Prices,  pakistan, mobile phone, Mobile Phone Pakistan, phone, prices, motorola, iphone, HTC, zone"
      ></meta>
      <meta name="sitecode" content="PK" ></meta>
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
}

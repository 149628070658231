import React from "react";
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Box,
  Link as ExternalLink,
} from "@material-ui/core";
import { Link } from "gatsby";
import { Close as CloseIcon } from "@material-ui/icons";
import styles from "../../css/main.module.scss";
import styles2 from "./SideMenu.module.scss";
import logo from "../../images/logoN.svg";

export default function SideMenu(props) {
  const { onClose, isMobile, sideMenuCollections } = props;

  const sortByOrder = (list) => {
    list.sort((a, b) => a.order - b.order);
    return list;
  };

  return (
    <React.Fragment>
      <Paper
        elevevation={0}
        style={{
          width: isMobile ? "100%" : "298px",
          height: "100%",
          boxShadow: "none",
        }}
      >
        <Grid
          container
          style={{ position: "relative", padding: "10px" }}
          className={styles2.sideMenu}
        >
          <Box
            style={{
              paddingLeft: "38px",
              paddingTop: "5px",
              paddingRight: "38px",
              paddingBottom: "10px",
              borderBottom: "1px solid #eee",
              width: "100%",
              textAlign: "center",
            }}
          >
            <img src={logo} alt="Electroniks Logo" width={196} height={35} />
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              size="medium"
              style={{
                position: "absolute",
                top: "10px",
                right: "5px",
              }}
              className={[styles.displayiBlock, styles.vam].join(" ")}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {sortByOrder(sideMenuCollections || []).map((c, i) => (
            <Box className={styles2.sideLinks} key={i}>
              <Typography variant="h6">{c.title}</Typography>
              {c.items.map((o, k) => (
                <Link key={k} to={`${o.url || "#"}`}>
                  {o.title}
                </Link>
              ))}
            </Box>
          ))}
          <Box className={styles2.sideLinks2}>
            <Typography variant="h6">&nbsp;</Typography>
            <Link to="/">Home</Link>
            <Link to="/all-brands/">All Brands</Link>
            <ExternalLink href="https://www.electroniks.pk/blog/">
              Blog and News
            </ExternalLink>
            <ExternalLink href="https://www.electroniks.pk/blog/contact-us/">
              Contact Us
            </ExternalLink>
            <ExternalLink href="https://www.electroniks.pk/blog/privacy-policy/">
              Privacy Policy
            </ExternalLink>
            <ExternalLink href="https://www.electroniks.pk/blog/terms-and-conditions/">
              Terms and Conditions
            </ExternalLink>
          </Box>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

import { createMuiTheme } from "@material-ui/core/styles"
import WorkSansWoff2 from "../../fonts/work-sans-v7-latin/work-sans-v7-latin-regular.woff2"
import WorkSansWoff2600 from "../../fonts/work-sans-v7-latin/work-sans-v7-latin-600.woff2"

const workSans = {
  fontFamily: "WorkSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('WorkSans'),
    local('WorkSans-Regular'),
    url(${WorkSansWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const workSansBold600 = {
  fontFamily: "WorkSans",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
    local('WorkSans'),
    local('WorkSans-600'),
    url(${WorkSansWoff2600}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0a182d",
      contrastText: "#fff",
      dark: "#0a182d"
    },
    secondary: {
      main: "#e6c446",
      contrastText: "#0a182d",
    },
    link: "#2f86c2"
    
  },
  typography: {
    fontFamily: "WorkSans, Roboto, Arial",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [workSans, workSansBold600],
      },
    },
  },
})

export default theme

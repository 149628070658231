import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  InputBase,
  Divider,
  Box,
  IconButton,
  LinearProgress,
  Grid,
  List,
  ListItemText,
  ListItem,
  ClickAwayListener,
} from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./SearchBox.module.scss";
import { search as searchApi } from "../../api";
import { navigate } from "gatsby";

var debounce = require("lodash/debounce");
const queryString = require("query-string");

const suggest = (q) => {
  return new Promise((resolve) => {
    searchApi
      .suggest({ q })
      .then((response) => {
        resolve({
          success: response.data ? true : false,
          data: response.data.data.records,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
      });
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  MuiLinearProgress: {
    root: { heigt: 3 },
  },
}));

export default function SearchBox(props) {
  let { onQueryChange } = props;
  const classes = useStyles();
  const tm = useRef(null);
  let qs = {};
  if (typeof window !== `undefined`) {
    qs = queryString.parse(window.location.search);
  }

  const [query, SetQuery] = useState(qs.q || "");
  const [loading, SetLoading] = useState(false);
  const [suggestions, SetSuggestions] = useState([]);
  const [locked, SetLocked] = useState(true);

  if (!onQueryChange) {
    onQueryChange = (q) => {
      navigate(`/search/?q=${q}`);
    };
  }

  useEffect(() => {
    tm.current = debounce(() => {
      if (!locked && query.length > 0) {
        (async () => {
          SetLoading(true);
          const result = await suggest(query);
          SetSuggestions(result.data || []);
          SetLoading(false);
        })();
      }
    }, 500);

    tm.current();
  }, [query, tm, locked, SetSuggestions]);

  return (
    <Paper
      style={{
        position: "relative",
        paddingBottom: suggestions.length > 0 ? "5px" : "0px",
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          SetSuggestions([]);
        }}
      >
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12}>
            <Box
              component="div"
              className={[classes.root, styles.searchBox].join(" ")}
            >
              <InputBase
                value={query}
                className={classes.input}
                placeholder="Search Mobile Phones, TV's, Samsung phones"
                inputProps={{ "aria-label": "search electroniks" }}
                onKeyUp={(evt) => {
                  if (evt.keyCode === 13) {
                    SetSuggestions([]);
                    onQueryChange(evt.target.value);
                  }
                }}
                onChange={(evt) => {
                  if (locked) {
                    SetLocked(false);
                  }

                  if (tm) {
                    tm.current.cancel();
                    tm.current = null;
                  }
                  SetQuery(evt.target.value);
                }}
              />
              <Box style={{ display: query.length > 0 ? "" : "none" }}>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    SetQuery("");
                    SetSuggestions([]);
                    onQueryChange("");
                  }}
                  type="submit"
                  className={classes.iconButton}
                  aria-label="clear-search"
                >
                  <ClearIcon />
                </IconButton>
              </Box>

              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
                onClick={(e) => {
                  e.preventDefault();
                  SetSuggestions([]);
                  onQueryChange(query);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" style={{ display: !loading ? "none" : "" }}>
              <LinearProgress style={{ height: "3px" }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="div"
              style={{ display: suggestions.length > 0 ? "" : "none" }}
            >
              <Paper
                square={true}
                style={{
                  zIndex: 15,
                  position: "absolute",
                  width: "100%",
                }}
              >
                <List dense={true}>
                  {suggestions.map((s, i) => (
                    <Box key={i}>
                      <ListItem
                        button
                        onClick={(e) => {
                          e.preventDefault();
                          SetQuery(s);
                          SetLocked(true);
                          SetSuggestions([]);
                          onQueryChange(s);
                        }}
                      >
                        <ListItemText primary={s} />
                      </ListItem>
                      {suggestions.length !== i + 1 ? (
                        <Divider />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Box>
                  ))}
                </List>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </Paper>
  );
}

const axios = require("axios");

const createAxiosInstance = (url) => {
  let instance = axios.create({
    baseURL: url,
  });

  instance.defaults.withCredentials = process.env.NODE_ENV === "development";
  instance.interceptors.request.use((config) => {
    return config;
  });

  return instance;
};

// const baseURL = process.env.API_URL || "http://localhost"
const baseURL = "https://www.electroniks.pk";
let strands = createAxiosInstance(`${baseURL}/api/v1/strands`);
let control = createAxiosInstance(`${baseURL}/api/v1/control`);
let genos = createAxiosInstance(`${baseURL}/api/v1/genos`);
let fridman = createAxiosInstance(`${baseURL}/api/v1/friedman`);

const collections = {
  list: (params) => strands.get(`/collections`, { params }),
  items: (id, params) => strands.get(`/collections/${id}/items`, { params }),
  getTagged: (tag) => strands.get(`/collections-tagged/${tag}`),
};

const product = {
  list: (params) => control.get(`/products`, { params }),
  comparisons: (id) => control.get(`/comparisons/${id}/products`),
};

const category = {
  fields: (category_id) => genos.get(`/categories/${category_id}/fields`),
};

const dataset = {
  get: (params) => genos.get(`/dataset-values`, { params }),
};

const search = {
  get: (params) => fridman.get(`/search`, { params }),
  similar: (id) => fridman.get(`/similar/${id}`),
  suggest: (params) => fridman.get(`/suggest`, { params }),
};

module.exports = { collections, product, search, category, dataset };

import React from "react";
import {Box, Typography, Link} from "@material-ui/core";
import logo from "../../images/logoN.svg";
import {Twitter as TwitterIcon, Facebook as FacebookIcon} from '@material-ui/icons';

import styles from "./Footer.module.scss";

export default function Footer(){
    return ( 
        
        <Box className={styles.foot}>
            <Box className={styles.disclaimer}>   
             <Typography variant="subtitle1" style={{fontSize:"11px"}}>
             <b>Disclaimer:</b> The electronics prices displayed on this website are updated as frequently as possible. They do not represent a single shop or store but are an average market price gathered from multiple sellers. We add the prices to give the visitors a general idea of what to expect in the market. Make sure to visit your local retailer for more accurate price.
             </Typography>
                 </Box>
            <Box className={styles.footLogo}>
                 <img src={logo} alt="Logo Electroniks"/>
            </Box>

            <Box className={styles.socialIcons}>
                 <Link title="Electroniks.pk Facebook Page" href="https://www.facebook.com/electroniks.pk" >
                     <FacebookIcon />
                     </Link>
                <Link title="Electroniks.pk Twitter Page" href="https://twitter.com/electronikspk" >
                    <TwitterIcon />
                    </Link>
            </Box>

            <Box className={styles.footLinks}>
                <Link href="https://www.electroniks.pk/blog/">Blog and News</Link>
                <Link href="https://www.electroniks.pk/blog/contact-us/">Contact Us</Link>
                <Link href="https://www.electroniks.pk/blog/privacy-policy/">Privacy Policy</Link>
                <Link href="https://www.electroniks.pk/blog/terms-and-conditions/"> Terms and Conditions</Link>
                
                </Box>
                <Typography variant="body2" style={{marginBottom:"0px", fontSize:"12px"}}>
                    Copyright &copy; 2021 Electroniks.pk
                    <br/>
                    <br/></Typography>

        </Box>

    );
};


import React, { useState } from "react";
import {
  Hidden,
  Grid,
  IconButton,
  Button,
  Drawer,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/logoN.svg";
import {
  BubbleChartOutlined as BubbleChartOutlinedIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import SearchBox from "./SearchBox";
import styles from "../../css/main.module.scss";
import styles2 from "./Header.module.scss";
import SideMenu from "../SideMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const SearchHead = (props) => {
  const { sideMenuCollections } = props;
  const classes = useStyles();
  const [openMenu, SetOpenMenu] = useState(false);

  return (
    <div style={{marginBottom: "5px"}}>
      <Hidden implementation="css" smDown>
        <Grid
          container
          className={[classes.root, styles2.headerMain].join(" ")}
          direction="row"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            spacing={1}
            className={styles2.headerMainWidth}
          >
            <Grid item container xs={3} alignItems="flex-start">
              <Grid item xs={12}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  size="medium"
                  className={[styles.displayiBlock, styles.vam].join(" ")}
                  onClick={(e) => {
                    e.preventDefault();
                    SetOpenMenu(!openMenu);
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  transitionDuration={0}
                  anchor={"left"}
                  open={openMenu}
                  onClose={() => SetOpenMenu(!openMenu)}
                >
                  <SideMenu
                    onClose={() => SetOpenMenu(false)}
                    sideMenuCollections={sideMenuCollections}
                    isMobile={false}
                  ></SideMenu>
                </Drawer>
                <Link
                  href="/"
                  className={[styles.displayiBlock, styles.vam].join(" ")}
                >
                  <img
                    src={logo}
                    alt="Electroniks Logo"
                    width={196}
                    height={35}
                  />
                </Link>
              </Grid>
            </Grid>

            <Grid container item xs={6} alignItems="stretch" justify="flex-end">
              <Grid item xs={12}>
                <SearchBox onQueryChange={props.onQueryChange} />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={3}
              alignContent="center"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Button
                edge="end"
                size="medium"
                color="secondary"
                variant="contained"
                aria-label="discover"
                href="/search/"
                className={styles2.discover}
              >
                <BubbleChartOutlinedIcon /> Discover
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden implementation="css" mdUp>
        <Grid
          container
          className={[classes.root, styles2.headerMain].join(" ")}
          direction="row"
          spacing={2}
        >
          <Grid container direction="row" item xs={12} spacing={1}>
            <Grid item container xs={1} alignItems="center" justify="center">
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: "10px",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  size="medium"
                  className={[styles.displayiBlock, styles.vam].join(" ")}
                  onClick={(e) => {
                    e.preventDefault();
                    SetOpenMenu(!openMenu);
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  transitionDuration={0}
                  anchor={"left"}
                  open={openMenu}
                  onClose={() => SetOpenMenu(!openMenu)}
                >
                  <SideMenu
                    sideMenuCollections={sideMenuCollections}
                    isMobile={true}
                    onClose={() => SetOpenMenu(false)}
                  ></SideMenu>
                </Drawer>
              </Grid>
            </Grid>

            <Grid item container xs={10} alignItems="center" justify="center">
              <Grid item xs={12} align="center">
                <Link
                  href="/"
                  className={[styles.displayiBlock, styles.vam].join(" ")}
                >
                  <img
                    src={logo}
                    alt="Electroniks Logo"
                    width={210}
                    height={33}
                  />
                </Link>
              </Grid>
            </Grid>

            {/* Discover Button */}
            <Grid item container xs={1} alignItems="center" justify="center">
              <Grid
                item
                xs={12}
                style={{ overflow: "hidden", textAlign: "center" }}
              >
                <Button
                  edge="end"
                  size="medium"
                  color="secondary"
                  variant="contained"
                  aria-label="discover"
                  href="/search/"
                  className={styles2.discover}
                  style={{
                    width: "100%",
                    display: "inline",
                    padding: "0",
                    textAlign: "right",
                  }}
                >
                  <BubbleChartOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
            {/* Discover Button */}
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="center"
            alignContent="stretch"
            style={{ paddingTop: "0" }}
          >
            <Grid item xs={12} className={styles2.searchPadding}>
              <SearchBox onQueryChange={props.onQueryChange} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};

export default SearchHead;
